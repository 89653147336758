import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import "./App.css";

// Register Chart.js components
Chart.register(...registerables);

// Utility function to shuffle the array (Fisher-Yates shuffle algorithm)
const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

function App() {
  const [lines, setLines] = useState([]); // Store list of words or sentences
  const [currentLineIndex, setCurrentLineIndex] = useState(0); // Track current word or sentence
  const [typedInput, setTypedInput] = useState(""); // Capture user input
  const [accuracy, setAccuracy] = useState(100); // Real-time accuracy
  const [mistypedChars, setMistypedChars] = useState({}); // Track mistyped characters
  const [timer, setTimer] = useState(120); // Set 2-minute timer (default)
  const [showBreakOverlay, setShowBreakOverlay] = useState(false); // Show break
  const [correctCharsSession, setCorrectCharsSession] = useState(0); // Correct chars
  const [incorrectCharsSession, setIncorrectCharsSession] = useState(0); // Incorrect chars
  const [totalWordsTyped, setTotalWordsTyped] = useState(0); // Track total words for WPM

  const [drillPeriod, setDrillPeriod] = useState(120); // User-set drill period (default 120s)
  const [drillScores, setDrillScores] = useState([]); // List of drill accuracy scores
  const inputRef = useRef(null); // Ref to input field for auto focus
  const [isDrillActive, setIsDrillActive] = useState(false); // Track if the drill has started
  const endgameSound = new Audio('/sounds/endgame-sound.mp3');
  const upliftingSound = new Audio('/sounds/uplifting-sound.mp3');

// New state for predefined files
  const [availableFiles, setAvailableFiles] = useState([]); // List of predefined files
  const [selectedFile, setSelectedFile] = useState(""); // Track selected file

  // Function to download report card
  const downloadReport = () => {
    const date = new Date().toLocaleString();
    const csvContent = `Date,Drill Scores\n${date},\n${drillScores.join("\n")}`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `report_card_${date.replace(/\/|:/g, '-')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

// Handle predefined file selection
  const handlePredefinedFileChange = async (e) => {
    const selectedFileName = e.target.value;
    setSelectedFile(selectedFileName);

    const response = await fetch(`/textfiles/${selectedFileName}`);
    const text = await response.text();

    const lineArray = text.split(/\r?\n/).filter(line => line.trim() !== "");
    const shuffledLines = shuffleArray(lineArray);
    setLines(shuffledLines);
    setTimer(drillPeriod || 120);
    
    setIsDrillActive(true); // Set the drill as active
  };


  // Fetch the list of predefined text files when the app loads
  useEffect(() => {
    const fetchTextFiles = async () => {
      // For now, this is hardcoded. Replace it with a dynamic fetch if necessary.
      const predefinedFiles = ['01-Words-First100.txt', '02-Words-Second500.txt', 'Sentences-long-advanced.txt', 'Sentences-medium-basic.txt', 'Sentences-short-advanced.txt'];
      setAvailableFiles(predefinedFiles); // Set the files in state for the dropdown
    };

    fetchTextFiles();
  }, []);

  // Handle file upload and processing
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const lineArray = text.split(/\r?\n/).filter(line => line.trim() !== "");
      const shuffledLines = shuffleArray(lineArray);
      setLines(shuffledLines);
      setTimer(drillPeriod || 120);

      setIsDrillActive(true); // Set the drill as active
    };
    reader.readAsText(file);
  };


  // Handle drill period input
  const handleDrillPeriodChange = (e) => {
    setDrillPeriod(Number(e.target.value) || 120); // Default to 120 if empty
  };

  // Typing handler
  const handleTyping = (e) => {
    const input = e.target.value;
    const currentLine = lines[currentLineIndex];

    // Check if the user pressed the backspace key
    if (input.length < typedInput.length) {
      setTypedInput(input);
      return; // Do nothing if backspace is pressed, don't track this input
    }

    setTypedInput(input);

    // Count correct and incorrect characters so far
    let correctChars = 0;
    let incorrectChars = 0;

    for (let i = 0; i < input.length; i++) {
      if (input[i] === currentLine[i]) {
        correctChars++;
      } else {
        incorrectChars++;
        // Track mistyped characters
        setMistypedChars((prevMistypedChars) => ({
          ...prevMistypedChars,
          [input[i]]: (prevMistypedChars[input[i]] || 0) + 1,
        }));
      }
    }

    // Accumulate session stats
    setCorrectCharsSession((prev) => prev + correctChars);
    setIncorrectCharsSession((prev) => prev + incorrectChars);

    // Update total accuracy for the session
    const totalChars = correctCharsSession + incorrectCharsSession + correctChars + incorrectChars;
    const newAccuracy = totalChars === 0 ? 100 : ((correctCharsSession + correctChars) / totalChars) * 100;
    setAccuracy(newAccuracy);

    // If the line is fully typed correctly followed by space, move to the next line
    if (input.trim() === currentLine) {
      // Count words typed
      const wordsInLine = currentLine.trim().split(/\s+/).length; // Split by spaces to get word count
      setTotalWordsTyped(prev => prev + wordsInLine); // Add to total words typed

      setCurrentLineIndex(currentLineIndex + 1); // Move to next line
      setTypedInput(""); // Reset input
    }
  };

  // Timer logic for drill period and 20-second break
useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      // Set focus on input field when the drill starts
      if (inputRef.current) {
        inputRef.current.focus();
      }

      return () => clearInterval(countdown);
    } else if (timer === 0) {
      // Play endgame sound to start the break
      endgameSound.play();
      // Freeze input and show break overlay
      setShowBreakOverlay(true);

      // Report session accuracy and WPM
      const totalChars = correctCharsSession + incorrectCharsSession;
      const sessionAccuracy = totalChars === 0 ? 100 : (correctCharsSession / totalChars) * 100;

      // Calculate WPM based on the number of words typed and the time taken
      const timeInMinutes = drillPeriod / 60;  // Convert time from seconds to minutes
      const wpm = totalWordsTyped / timeInMinutes;

      const newDrillScore = `Drill ${drillScores.length + 1} accuracy: ${sessionAccuracy.toFixed(2)}%, WPM: ${wpm.toFixed(2)}`;
      setDrillScores([...drillScores, newDrillScore]);

      // Reset stats for the next drill period
      setCorrectCharsSession(0);
      setIncorrectCharsSession(0);
      setTotalWordsTyped(0); // Reset total words for WPM
      setAccuracy(100); // Reset accuracy for the next period

      setTimeout(() => {
        // Play uplifting sound to start the next drill
        upliftingSound.play();
        setShowBreakOverlay(false);
        setTimer(drillPeriod || 120); // Restart drill period with user-defined value or default
      }, 20000); // 20-second break
    }
  }, [timer, drillPeriod]);

  // Prepare the bar chart data for mistyped characters
  const barChartData = {
    labels: Object.keys(mistypedChars).slice(0, 10), // Show top 10 mistyped characters
    datasets: [
      {
        label: "Mistyped Characters",
        data: Object.values(mistypedChars).slice(0, 10), // Frequency of mistyped characters
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

 return (
  <div className="App">
    <h1>Typer v0.1</h1>

    {!isDrillActive && (
      <div>
        <div className="description-text">
          <p>
            Some of the most recent neuroscience suggests that time-spaced learning can dramatically benefit the learner in total "time to learn a new skill." It seems something magical happens when you take periodic 20-second breaks after a period of drilling. It's important to close your eyes during this break period and rest your mind (don't wander). Under the right circumstances, the neurons you've been working will begin to work 10x faster over the same pathways, dramatically increasing the "etching" of those neuro pathways. 
          </p>
          <p>
            I created this little app to try to help typing speed in the same way. Set the duration you want for your drills, and after that time has expired, you'll be prompted (including with a cute sound) to stop the drill. Close your eyes as soon as this happens, and listen for another cute sound to signal when you should proceed.
          </p>
        </div>
        <div>
          <label>Set Drill Period (seconds): </label>
          <input type="number" onChange={handleDrillPeriodChange} placeholder="120" />
        </div>

        {/* Dropdown for predefined text files */}
        <div>
          <label>Select Predefined File: </label>
          <select onChange={handlePredefinedFileChange}>
            <option value="">Select a file</option>
            {availableFiles.map((file, index) => (
              <option key={index} value={file}>
                {file}
              </option>
            ))}
          </select>
        </div>

        {/* File upload option */}
        <div>
          <label>Or Upload Custom File: </label>
          <input type="file" onChange={handleFileUpload} accept=".txt" />
        </div>
      </div>
    )}

      {lines.length > 0 && (
        <div>
          <h2>Type the line:</h2>
          <h2 className="drill-word">{lines[currentLineIndex]}</h2>
          <input
            type="text"
            value={typedInput}
            onChange={handleTyping}
            ref={inputRef}
            disabled={showBreakOverlay}
          />
          <p>Accuracy: {accuracy.toFixed(2)}%</p>
          <p>Time Left: {timer}s</p>
          {/* Bar Chart for Mistyped Characters */}
          <div className="bar-chart-container">
            <Bar data={barChartData} />
          </div>
          {/* List of drill scores */}
          <div>
            <h3>Drill Scores</h3>
            <ul>
              {drillScores.map((score, index) => (
                <li key={index}>{score}</li>
              ))}
            </ul>
          </div>
          {/* Button to download report card */}
          <button onClick={downloadReport}>Download Report Card</button>
        </div>
      )}
      {showBreakOverlay && (
        <div className="overlay">
          <h2>Take a 20-second break!</h2>
        </div>
      )}
    </div>
  );
}

export default App;